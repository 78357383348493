import * as React from "react"
import { useState } from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import LocationList from "../../../components/location-list"
import AwardsList from "../../../components/awards-list"

import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./the-pump-house.yaml"

const ThePumpHouse = () => {
  const [locations] = useState(YAMLdata.locations)
  const [awards] = useState(YAMLdata.awards)
  return (
    <Layout>
      <Seo
        title={`${YAMLdata.title} - ${YAMLdata.category}`}
        description="We specialise in F&amp;B, Leisure and Luxury spaces - crafting a complete guest experience through a multidisciplinary approach."
      />
      {/* hero image and title */}
      <section className="pdetails_hero_area">
        <div className="pdetails_hero_outer">
          <div className="pdetails_hero_img">
            <StaticImage
              src="../../../images/projects/the-pump-house/the-pump-house-hero.jpg"
              alt={YAMLdata.title}
              layout="fullWidth"
            />
            <div className="pdetails_hero_heading">
              <h2>{YAMLdata.title}</h2>
            </div>
          </div>
          <div className="pdetails_hero_catg pd_mnt">
            <div className="pdetails_hero_category">
              <p>{YAMLdata.category}</p>
            </div>
            <div className="pdetails_hero_icon">
              <StaticImage
                src="../../../images/projects/microbreweries-taprooms-icon_ts.svg"
                alt="Microbreweries / Taprooms icon"
              />
            </div>
          </div>
        </div>
      </section>
      {/* primary image and details */}
      <section className="pdetails_discp">
        <div className="pdet_bg">
          <Container>
            <Row className="no-gutters">
              <Col sm={7}>
                {locations ? <LocationList locdata={locations} /> : ""}
              </Col>
              <Col sm={5}>{awards ? <AwardsList awardata={awards} /> : ""}</Col>
            </Row>
          </Container>
          <div className="pdetails_prime">
            <StaticImage
              src="../../../images/projects/the-pump-house/the-pump-house-primary.jpg"
              alt={YAMLdata.title}
              layout="fullWidth"
            />
          </div>
        </div>
      </section>
      {/* secondary image */}
      <section className="pdetails_more">
        <div className="pdet_bg">
          <div className="pdetails_second">
            <StaticImage
              src="../../../images/projects/the-pump-house/the-pump-house-secondary.jpg"
              alt={YAMLdata.title}
              layout="fullWidth"
            />
          </div>
        </div>
      </section>
      <section className="pdetails_coll p_x">
        <Container>
          <Row className="no-gutters">
            <Col xl={8}>
              <div className="pdet_colls">
                <div className="pdet_img_left">
                  <StaticImage
                    src="../../../images/projects/the-pump-house/the-pump-house-left.jpg"
                    alt={YAMLdata.title}
                  />
                </div>
                <div className="pdet_imgs_right">
                  <div className="pdet_img_top">
                    <StaticImage
                      src="../../../images/projects/the-pump-house/the-pump-house-top.jpg"
                      alt={YAMLdata.title}
                    />
                  </div>
                  <div className="pdet_img_btm">
                    <StaticImage
                      src="../../../images/projects/the-pump-house/the-pump-house-bottom.jpg"
                      alt={YAMLdata.title}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} className="pdet_copy_bg">
              <div className="pdet_copy">
                <h3 className="black-dash">{YAMLdata.heading}</h3>
                <p>{YAMLdata.content}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default ThePumpHouse
